import styled from "styled-components";
import { Link } from "gatsby";

export const Card = styled(Link)`
  background-color: white;
  width: 90%;
  max-width: 600px;
  min-height: 250px;
  margin: auto;
  margin-bottom: 40px;
  border-radius: 10px;
  padding: 10px;
  color: black;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  &:hover {
    transform: scale(1.05);
  }
`;

export const CardTitle = styled.h1`
  font-size: 22px;
  color: #c90225;
`;

export const CardSubtitle = styled.div``;

export const CardBody = styled.article``;

export const ReadMore = styled.div`
  background-color: #c90225;
  color: white;
  width: 160px;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0 0 auto;
`;
