import React from "react";

import { Container, ButtonContainer, Button } from "./styles";

function BlogNavigation({ currentPage, totalPages }) {
  let prevPath = `/blog/${currentPage - 1}`;
  if (currentPage === 2) prevPath = "/blog";
  const nextPath = `/blog/${currentPage + 1}`;

  return (
    <Container style={{ display: totalPages === 1 ? "none" : "flex" }}>
      <ButtonContainer>
        {currentPage !== 1 ? (
          <Button to={prevPath}>Página Anterior</Button>
        ) : null}
        {currentPage !== totalPages ? (
          <Button to={nextPath}>Próxima Página</Button>
        ) : null}
      </ButtonContainer>
    </Container>
  );
}

export default BlogNavigation;
