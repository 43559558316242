import React from "react";

import { Card, CardTitle, CardSubtitle, CardBody, ReadMore } from "./styles";

function Post({ title, date, slug, body }) {
  return (
    <Card to={slug}>
      <CardTitle>{title}</CardTitle>
      <CardSubtitle>
        <span>{date}</span>
      </CardSubtitle>
      <CardBody>{body}</CardBody>
      <ReadMore>Continuar lendo...</ReadMore>
    </Card>
  );
}

export default Post;
