import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Post from "../components/Post";
import Contato from "../components/Contato";
import BlogNavigation from "../components/BlogNavigation";
import { BlogHeader, BlogImage, BlogTitle, PostList } from "./styles";

import newspaper from "../images/newspaper.png";

function BlogPage({ data, pageContext }) {
  const { currentPage, numberOfPages } = pageContext;

  return (
    <Layout>
      <SEO
        title="Blog"
        description="Veja as últimas notícias na Martins Treinamentos e Consultoria"
      />
      <BlogHeader>
        <BlogImage src={newspaper} alt="Notícias" />
        <BlogTitle>Blog Martins</BlogTitle>
      </BlogHeader>
      <PostList>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <Post
            title={node.frontmatter.title}
            date={node.frontmatter.date}
            slug={node.fields.slug}
            body={node.excerpt}
            key={Math.random()}
          />
        ))}
      </PostList>
      <BlogNavigation currentPage={currentPage} totalPages={numberOfPages} />
      <Contato />
    </Layout>
  );
}

export const blogPageQuery = graphql`
  query postsQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD/MM/YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;

export default BlogPage;
