import styled from "styled-components";
import { Link } from "gatsby";

const MEDIA = "@media only screen and (max-width: 900px)";

export const Container = styled.div`
  width: 100%;
  padding: 50px;
  align-items: center;
  justify-content: flex-end;

  ${MEDIA} {
    justify-content: center;
  }
`;

export const ButtonContainer = styled.div`
  ${MEDIA} {
    width: 200px;
    display: flex;
    flex-direction: column;
  }
`;

export const Button = styled(Link)`
  margin-left: 20px;
  color: white;
  padding: 10px;
  background-color: #c90225;

  &:hover {
    filter: brightness(70%);
  }

  ${MEDIA} {
    margin-left: 0;
    margin-bottom: 10px;
  }
`;
